// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api: 'https://api.testship.net/9054AC56-9E4E-646C-FF2F-6DEB2FE02300/E9B385EB-F29F-4AC0-A649-C162309D82E0',
  awbApi: 'https://api.testship.net/9054AC56-9E4E-646C-FF2F-6DEB2FE02300/F9F7681E-24D6-4167-AC40-23A855CDA0C0/services/awb/',
  exportBulk: 'https://services-dev.testship.net/',
  awb: 'https://doc-dev.testship.net',
  googleMapsKey: 'AIzaSyAZfuZJA3-KQ03st0ZxLSqz6_--xl7-Kkk',
  configParams: {
    limitOfAWBsToPrint: 500,
    limitOfPODsToPrintSingle: 50,
    limitOfPODsToPrintList: 300,
    limitOfMaxBulkOrders: 2000,
    limitOfInventoryItems: 10000,
  },
  chat: {
    appId: 'wymbkmiz',
    enabled: true,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
